import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EventTemplateLayout } from '@models/design-templates/event-template-layout.enum';
import { EventType } from '@models/events/event-type.enum';
import { AdvancedOneColumnEventComponent } from '@modules/events/components/advanced-one-column-event/advanced-one-column-event.component';
import { EventComponent } from '@modules/events/components/event/event.component';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import {
  Event,
  createExampleEventForTemplatePreview,
} from '@models/events/event.model';
import { InvitationEventComponent } from '@modules/events/components/invitation-event/invitation-event.component';
import { SportPageEventComponent } from '@modules/events/components/sportpage-event/sportpage-event.component';
import { WeddingpageEventComponent } from '@modules/events/components/weddingpage-event/weddingpage-event.component';
import { TranslateModule } from '@ngx-translate/core';
import { EventStore } from '@services/stores/event.store';
import { EventService } from '@services/events/event.service';
import { Helpers } from '@utils/helpers';
import { EventTemplateFont } from '@models/design-templates/event-template-font.model';
import { EventTemplate } from '@models/design-templates/event-template.model';
import { MatMenuModule } from '@angular/material/menu';
import { EventTemplateVisual } from '@models/design-templates/event-template-visual.model';
import { environment as env } from '@environments/environment';
import { User } from '@models/users/user.model';
import { AuthService } from '@services/auth/auth.service';
import { ScreenWidthService } from '@services/shared/screen-width.service';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { TemplateUtils } from '@utils/template-utils';

@Component({
  selector: 'app-template-preview-overlay-page',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    MatMenuModule,
    EventComponent,
    AdvancedOneColumnEventComponent,
    InvitationEventComponent,
    SportPageEventComponent,
    WeddingpageEventComponent,
  ],
  providers: [EventService, EventStore, ScreenWidthService],
  templateUrl: './template-preview-overlay-page.component.html',
  styleUrl: './template-preview-overlay-page.component.scss',
})
export class TemplatePreviewOverlayPageComponent implements OnInit, OnDestroy {
  event?: Event;
  eventTemplate?: EventTemplate;

  isAdminPreview = false;
  isAIMoodBoardPreview = false;

  inProgress = false;
  showOtherActions?: boolean = true;

  loggedUser?: User;
  isDesktop?: boolean;

  constructor(
    public dialogRef: MatDialogRef<TemplatePreviewOverlayPageComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private eventStore: EventStore,
    private authService: AuthService,
    private screenWidthService: ScreenWidthService,
    private dialog: MatDialog,
    private bottomSheet: MatBottomSheet,
  ) {
    this.authService.userSubject
      .pipe(takeUntilDestroyed())
      .subscribe((user) => {
        this.loggedUser = user;
      });
    this.screenWidthService
      .isDesktop()
      .pipe(takeUntilDestroyed())
      .subscribe({
        next: (isDesktop: boolean) => {
          this.isDesktop = isDesktop;
        },
      });

    this.eventTemplate = data.eventTemplate;
    this.isAdminPreview = data.isAdminPreview;
    this.isAIMoodBoardPreview = data.isAIMoodBoardPreview;

    this.event = createExampleEventForTemplatePreview(data.eventTemplate);
    this.eventStore.setEvent(this.event);
    if (data?.showOtherActions !== undefined) {
      this.showOtherActions = data.showOtherActions;
    }
  }

  ngOnInit() {
    if (this.font) {
      Helpers.setEventTheme(
        this.font.primaryLogoFontColor,
        this.font.bodyFontColor,
        this.visual?.backgroundColor,
      );

      Helpers.setEventSectionsTheme(
        this.font.sectionBodyColor,
        this.font.sectionBackgroundColor,
        this.font.sectionAccentBodyColor,
        this.font.sectionAccentBackgroundColor,
      );
    }
  }

  get font(): EventTemplateFont | undefined {
    return this.event?.eventTemplate?.font;
  }

  get visual(): EventTemplateVisual | undefined {
    return this.event?.eventTemplate?.visual;
  }

  close() {
    this.dialogRef.close();
  }

  useTemplate() {
    if (!this.eventTemplate) return;

    if (
      this.eventTemplate.type !== EventType.WEDDINGPAGE &&
      !TemplateUtils.canApplyProTemplate(this.eventTemplate, this.loggedUser)
    ) {
      return this.openUnlockTemplate();
    }

    this.dialogRef.close({
      useTemplate: true,
    });
  }

  startWithAi() {
    this.dialogRef.close({
      startWithAI: true,
    });
  }

  useMoodBoard() {
    this.inProgress = true;
    this.dialogRef.close({
      useMoodBoard: true,
    });
  }

  share() {
    if (this.eventTemplate) {
      window.navigator.share({
        title: this.eventTemplate.name,
        url: this.eventTemplate.getLink(),
      });
    }
  }

  report() {}

  async openUnlockTemplateDialog() {
    const { UnlockTemplateDialogComponent } = await import(
      '@modules/events/components/select-template/dialogs/unlock-template-dialog/unlock-template-dialog.component'
    );

    this.dialog.open(UnlockTemplateDialogComponent, {
      maxWidth: '795px',
      maxHeight: '100vh',
      width: '100%',
      height: 'auto',
      panelClass: ['normal-dialog'],
      data: {
        eventTemplate: this.eventTemplate,
        loggedUser: this.loggedUser,
      },
      autoFocus: false,
    });
  }

  async openUnlockTemplateBottomSheet() {
    const { UnlockTemplateBottomSheetComponent } = await import(
      '@modules/events/components/select-template/dialogs/unlock-template-bottom-sheet/unlock-template-bottom-sheet.component'
    );

    this.bottomSheet.open(UnlockTemplateBottomSheetComponent, {
      panelClass: 'bottom-sheet-dialog',
      data: {
        eventTemplate: this.eventTemplate,
        loggedUser: this.loggedUser,
      },
    });
  }

  openUnlockTemplate() {
    if (this.isDesktop) {
      this.openUnlockTemplateDialog();
    } else {
      this.openUnlockTemplateBottomSheet();
    }
  }

  ngOnDestroy(): void {
    Helpers.resetEventTheme();
    Helpers.resetEventSectionsTheme();
  }

  protected env = env;
  protected readonly EventType = EventType;
  protected readonly EventTemplateLayout = EventTemplateLayout;
}
